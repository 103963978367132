import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import { Link } from "gatsby"
import Prism from 'prismjs'
import './blogPost.scss'

class BlogPost extends React.Component {

  componentDidMount() {
    Prism.highlightAll();
  }

  render() {
    const post = this.props.data.nodeBlog

    return(
      <Layout>
      <div className="blogPost">
        <div className="blogPost__header">
          <Link to="/" className="blogPost__back">Back</Link>
          <h1 className='blogPost__title'>{post.title}</h1>
        </div>
        <div
            className="blogPost__content"
            dangerouslySetInnerHTML={{ __html: post.body.value }}
          />
      </div>
    </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($BlogId: String!) {
    nodeBlog(id: {eq: $BlogId}) {
      id
      title
      created(formatString: "MMMM DD, YYYY")
      body {
        value
      }
    }
  }
`

export default BlogPost
